<template>
  <div>
    <v-row
      row
      wrap
      no-gutters
      justify="center"
      align-content="center"
      align="center"
    >
      <v-col
        v-if="formData"
        :cols="$vuetify.breakpoint.smAndDown ? 11 : 6"
        align-self="center"
      >
        <div v-if="formResults.length != 0">
          <v-card height="100%" class="mainContainerBackground pa-2 mt-5 mb-2">
            <FlightFormResults
              :formResults="formResults"
              :formId="formId"
            ></FlightFormResults>
          </v-card>
        </div>
        <div v-else>
          <v-row row no-wrap no-gutters justify="center" style="height: 100vh">
            <v-col cols="12" align-self="start">
              <v-row justify="center" class="pt-10">
                <v-img
                  src="../assets/flight.png"
                  :height="$vuetify.breakpoint.smAndDown ? '5em' : '10em'"
                  contain
                ></v-img>
              </v-row>
            </v-col>

            <v-col cols="12" align-self="center">
              <v-stepper
                v-model="formStepper"
                non-linear
                class="mainContainerBackground pa-5"
              >
                <v-stepper-items>
                  <v-stepper-content
                    v-for="(question, index) in formData.sections
                      .map((i) => i.questions)
                      .flat()"
                    :step="index + 1"
                    :ripple="false"
                    :key="index"
                    class="pa-2"
                  >
                    <v-layout column>
                      <pqweq
                        class="textColor"
                        style="text-align: center"
                        v-if="formStepper == index + 1"
                      >
                        {{ $i18n.t(`questions.${question.id}.text`) }}
                      </pqweq>
                      <div
                        v-if="question.type == 'q_multiple_choice'"
                        class="mt-5"
                      >
                        <v-row row wrap no-gutters justify="center">
                          <v-col
                            :cols="$vuetify.breakpoint.smAndDown ? 6 : 3"
                            v-for="(questionOption, optionIndex) in JSON.parse(
                              question.options
                            )"
                            :key="optionIndex"
                            justify="center"
                          >
                            <v-row justify="center" no-gutters>
                              <div
                                class="questionBoxStyle"
                                @click="
                                  setUserAnswer(question, questionOption.label)
                                "
                                :style="
                                  question.user_answer == questionOption.label
                                    ? 'background:#B6DDF5'
                                    : ''
                                "
                              >
                                {{
                                  $i18n.t(
                                    `questions.${question.id}.options.${optionIndex}`
                                  )
                                }}
                              </div>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                    </v-layout>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-col>

            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? 10 : 4"
              align-self="end"
              class="mb-3"
            >
              <v-card class="mainContainerBackground pa-5">
                <v-row justify="space-around">
                  <v-icon
                    x-large
                    @click="formStepper--"
                    :disabled="formStepper == 1"
                  >
                    mdi-chevron-left
                  </v-icon>
                  <p style="margin-bottom: 0" class="text-xs-right textColor">
                    {{ formStepper }}/{{
                      formData.sections.map((i) => i.questions).flat().length
                    }}
                  </p>
                  <v-icon
                    x-large
                    @click="formStepper++"
                    :disabled="
                      questionAnswerNotFound ||
                      formStepper ==
                        formData.sections.map((i) => i.questions).flat().length
                    "
                  >
                    mdi-chevron-right
                  </v-icon>
                </v-row>
                <v-row justify="center" v-if="formIsDone">
                  <div class="goToResultButton" @click="goToResults">
                    Go to results
                  </div>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" align-self="start">
        <v-row justify="center" class="pt-10 mt-10">
          <v-img
            src="../assets/euLogos/CoFundedEnglish.png"
            :height="$vuetify.breakpoint.smAndDown ? '4em' : '6em'"
            contain
          ></v-img>
          <p style="color: black; z-index: 10; padding: 2em">
            {{ $t("euDisclaimer") }}
          </p>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FlightFormResults from "./FlightFormResults.vue";
export default {
  components: {
    FlightFormResults,
  },
  data() {
    return {
      formGuid: "13d62ea1-1020-4887-9cdf-33fe8c4bb4f2",
      formData: null,
      formStepper: 1,
      formResults: [],
      formId: null,
      formIsDone: false,
    };
  },
  methods: {
    getFlightForm() {
      this.$axios
        .get(
          `api/form_builder/responses?mode=getformresponseanddata&guid='${this.formGuid}'`
        )
        .then((response) => {
          this.formData = response.data;
          this.formId = response.data.sections[0].questions[0].form_id;
        });
    },
    setUserAnswer(question, answer) {
      question.user_answer = answer;
      if (
        this.formStepper !=
        this.formData.sections.map((i) => i.questions).flat().length
      ) {
        this.formStepper++;
      }
      if (
        this.formData.sections
          .map((i) => i.questions)
          .flat()
          .map((i) => i.user_answer)
          .filter((i) => i == null).length == 0
      ) {
        this.formIsDone = true;
      }
    },
    goToResults() {
      let getAllQuestions = this.formData.sections
        .map((i) => i.questions)
        .flat()
        .map((el) => {
          return {
            question_id: el.id,
            type: el.type,
            result_category: el.result_category_id,
            options: el.options,
            answer: el.user_answer,
          };
        });
      this.formResults = getAllQuestions;
    },
  },
  computed: {
    questionAnswerNotFound() {
      let findQuestion = this.formData.sections.map((i) => i.questions).flat()[
        this.formStepper - 1
      ];
      if (findQuestion && findQuestion.user_answer) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.getFlightForm();
  },
};
</script>

<style scoped>
.mainContainerBackground {
  /* background: linear-gradient(to bottom, #FFEE58 , #EF5350, #3949AB); */
  background: linear-gradient(to bottom, #b6ddf5, #fff);
  border-radius: 30px;
}
.textColor {
  /* color:#FFFFFFB3; */
  font-size: 24px;
}
.questionBoxStyle {
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid lightgrey;
  font-size: 26px;
  padding: 5px;
  text-align: center;
  width: 5em;
  background: #fff;
}
.questionBoxStyle:hover {
  background: #b6ddf5;
}
.goToResultButton {
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid lightgrey;
  font-size: 26px;
  padding: 5px;
  text-align: center;
  width: 8em;
  background: #fff;
}
</style>
