<template>
  <div>
    <v-row
      row
      wrap
      no-gutters
      justify="center"
      align-content="center"
      align="center"
    >
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 11 : 9" align-self="center">
        <div>
          <v-row row no-wrap no-gutters justify="center" style="height: 100vh">
            <v-col cols="12" align-self="start">
              <v-row justify="center" class="pt-10">
                <v-img
                  src="../assets/flight.png"
                  :height="$vuetify.breakpoint.smAndDown ? '5em' : '10em'"
                  contain
                ></v-img>
              </v-row>
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? 12 : 8"
              class="mt-5 mb-5"
            >
              <div>
                <v-stepper
                  v-model="infoPageStepper"
                  class="mainContainerBackground pa-2"
                  :class="
                    $vuetify.breakpoint.smAndDown
                      ? 'textSizeMobile'
                      : 'textSize'
                  "
                >
                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <div>
                        <v-row justify="center">
                          <v-col cols="6">
                            <v-select
                              v-model="selectedLanguage"
                              item-value="text"
                              :items="availableCountries"
                              :label="$i18n.t('actions.select_language')"
                              return-object
                            ></v-select>
                          </v-col>
                        </v-row>
                      </div>
                      <p>{{ $i18n.t("intro_page_1_header") }}</p>
                      <p>{{ $i18n.t("intro_page_1_text") }}</p>
                      <p>{{ $i18n.t("intro_page_1_duration") }}</p>
                      <div class="pt-5">
                        <v-row justify="center">
                          <div
                            class="goToResultButton"
                            @click="infoPageStepper = 2"
                          >
                            {{ $i18n.t("actions.next_page") }}
                          </div>
                        </v-row>
                      </div>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                      <p>{{ $i18n.t("intro_page_2_header") }}</p>
                      <p v-html="$i18n.t('intro_page_2_text')"></p>
                      <div class="pt-5">
                        <v-row justify="center">
                          <div
                            class="goToResultButton"
                            :class="
                              $vuetify.breakpoint.smAndDown
                                ? 'textSizeMobile'
                                : 'textSize'
                            "
                            @click="$emit('goToForm')"
                          >
                            {{ $i18n.t("actions.start_test") }}
                          </div>
                        </v-row>
                      </div>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" align-self="start">
        <v-row justify="center" class="pt-10 mt-10">
          <v-img
            src="../assets/euLogos/CoFundedEnglish.png"
            :height="$vuetify.breakpoint.smAndDown ? '4em' : '6em'"
            contain
          ></v-img>
          <p style="color: black; z-index: 10; padding: 2em">
            {{ $t("euDisclaimer") }}
          </p>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      availableCountries: [
        { text: "Norway", value: "no" },
        { text: "Denmark", value: "dk" },
        { text: "Poland", value: "pl" },
        { text: "Lithuania", value: "lt" },
        // { text: "Germany", value: "de" },
        // { text: "Ukraine", value: "ua" },
        { text: "English", value: "en" },
      ],
      infoPageStepper: 1,
      selectedLanguage: { text: "English", value: "en" },
    };
  },
  watch: {
    selectedLanguage() {
      this.$i18n.locale = this.selectedLanguage.value;
    },
  },
};
</script>

<style scoped>
.mainContainerBackground {
  /* background: linear-gradient(to bottom, #FFEE58 , #EF5350, #3949AB); */
  background: linear-gradient(to bottom, #b6ddf5, #fff);
  border-radius: 30px;
}
.textSize {
  /* color:#FFFFFFB3; */
  font-size: 20px;
}
.textSizeMobile {
  font-size: 14px;
}
.goToResultButton {
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid lightgrey;
  font-size: 26px;
  padding: 5px;
  text-align: center;
  width: 8em;
  background: #fff;
  margin: 20px;
}
</style>
