var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"row":"","wrap":"","no-gutters":"","justify":"center","align-content":"center","align":"center"}},[(_vm.formData)?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 11 : 6,"align-self":"center"}},[(_vm.formResults.length != 0)?_c('div',[_c('v-card',{staticClass:"mainContainerBackground pa-2 mt-5 mb-2",attrs:{"height":"100%"}},[_c('FlightFormResults',{attrs:{"formResults":_vm.formResults,"formId":_vm.formId}})],1)],1):_c('div',[_c('v-row',{staticStyle:{"height":"100vh"},attrs:{"row":"","no-wrap":"","no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","align-self":"start"}},[_c('v-row',{staticClass:"pt-10",attrs:{"justify":"center"}},[_c('v-img',{attrs:{"src":require("../assets/flight.png"),"height":_vm.$vuetify.breakpoint.smAndDown ? '5em' : '10em',"contain":""}})],1)],1),_c('v-col',{attrs:{"cols":"12","align-self":"center"}},[_c('v-stepper',{staticClass:"mainContainerBackground pa-5",attrs:{"non-linear":""},model:{value:(_vm.formStepper),callback:function ($$v) {_vm.formStepper=$$v},expression:"formStepper"}},[_c('v-stepper-items',_vm._l((_vm.formData.sections
                    .map((i) => i.questions)
                    .flat()),function(question,index){return _c('v-stepper-content',{key:index,staticClass:"pa-2",attrs:{"step":index + 1,"ripple":false}},[_c('v-layout',{attrs:{"column":""}},[(_vm.formStepper == index + 1)?_c('pqweq',{staticClass:"textColor",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$i18n.t(`questions.${question.id}.text`))+" ")]):_vm._e(),(question.type == 'q_multiple_choice')?_c('div',{staticClass:"mt-5"},[_c('v-row',{attrs:{"row":"","wrap":"","no-gutters":"","justify":"center"}},_vm._l((JSON.parse(
                            question.options
                          )),function(questionOption,optionIndex){return _c('v-col',{key:optionIndex,attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3,"justify":"center"}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('div',{staticClass:"questionBoxStyle",style:(question.user_answer == questionOption.label
                                  ? 'background:#B6DDF5'
                                  : ''),on:{"click":function($event){return _vm.setUserAnswer(question, questionOption.label)}}},[_vm._v(" "+_vm._s(_vm.$i18n.t( `questions.${question.id}.options.${optionIndex}` ))+" ")])])],1)}),1)],1):_vm._e()],1)],1)}),1)],1)],1),_c('v-col',{staticClass:"mb-3",attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 10 : 4,"align-self":"end"}},[_c('v-card',{staticClass:"mainContainerBackground pa-5"},[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-icon',{attrs:{"x-large":"","disabled":_vm.formStepper == 1},on:{"click":function($event){_vm.formStepper--}}},[_vm._v(" mdi-chevron-left ")]),_c('p',{staticClass:"text-xs-right textColor",staticStyle:{"margin-bottom":"0"}},[_vm._v(" "+_vm._s(_vm.formStepper)+"/"+_vm._s(_vm.formData.sections.map((i) => i.questions).flat().length)+" ")]),_c('v-icon',{attrs:{"x-large":"","disabled":_vm.questionAnswerNotFound ||
                    _vm.formStepper ==
                      _vm.formData.sections.map((i) => i.questions).flat().length},on:{"click":function($event){_vm.formStepper++}}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.formIsDone)?_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticClass:"goToResultButton",on:{"click":_vm.goToResults}},[_vm._v(" Go to results ")])]):_vm._e()],1)],1)],1)],1)]):_vm._e(),_c('v-col',{attrs:{"cols":"12","align-self":"start"}},[_c('v-row',{staticClass:"pt-10 mt-10",attrs:{"justify":"center"}},[_c('v-img',{attrs:{"src":require("../assets/euLogos/CoFundedEnglish.png"),"height":_vm.$vuetify.breakpoint.smAndDown ? '4em' : '6em',"contain":""}}),_c('p',{staticStyle:{"color":"black","z-index":"10","padding":"2em"}},[_vm._v(" "+_vm._s(_vm.$t("euDisclaimer"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }