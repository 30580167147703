<template>
  <!-- <v-card class="home gradientBackgroundColor" height="100%"> -->
  <v-card class="home" height="100%">
    <v-row
      row
      wrap
      no-gutters
      justify="center"
      align="center"
      style="height: 100%"
    >
      <v-col cols="12">
        <video autoplay muted loop class="videoPosistion">
          <source src="../assets/cloud_loop.mp4" type="video/mp4" />
        </video>
        <div v-if="showForm">
          <FlightForm></FlightForm>
        </div>
        <div v-else>
          <FrontPage @goToForm="goToForm"></FrontPage>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
// @ is an alias to /src
import FlightForm from "@/components/FlightForm.vue";
import FrontPage from "@/components/FrontPage.vue";

export default {
  name: "Home",
  components: {
    FlightForm,
    FrontPage,
  },
  data() {
    return {
      showForm: false,
    };
  },
  methods: {
    goToForm() {
      this.showForm = true;
    },
  },
};
</script>

<style scoped>
.gradientBackgroundColor {
  background: linear-gradient(to right, #ffee58, #ef5350, #3949ab);
}
.videoPosistion {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
</style>
