import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en";
import ua from "./ua";
import dk from "./dk";
import lt from "./lt";
import pl from "./pl";
import no from "./no";
Vue.use(VueI18n);

export default new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en,
    ua,
    dk,
    lt,
    pl,
    no
  },
});
