<template>
  <div class="pa-5" v-if="userProfile">
    <v-layout row wrap justify-center>
      <v-flex xs10>
        <div class="font-weight-light text-center pa-2">
          <div
            :class="
              $vuetify.breakpoint.smAndDown ? 'textSizeMobile' : 'textSize'
            "
          >
            <p>
              <b>{{ userProfile.text }}</b>
            </p>
            <!-- <p>{{ $i18n.t("romantic_fraud.result_text_1") }}</p> -->
            <p>{{ $i18n.t("romantic_fraud.result_text_2") }}</p>
            <p>{{ $i18n.t("romantic_fraud.result_text_3") }}</p>
            <p>{{ $i18n.t("romantic_fraud.result_text_4") }}</p>
            <p>{{ $i18n.t("romantic_fraud.result_text_5") }}</p>
            <p>{{ $i18n.t("romantic_fraud.result_text_6") }}</p>
            <p>{{ $i18n.t("romantic_fraud.result_text_7") }}</p>
            <p>{{ $i18n.t("romantic_fraud.result_text_8") }}</p>
            <p>{{ $i18n.t("romantic_fraud.result_text_9") }}</p>
          </div>

          <br />
          <!-- <v-text-field
            v-model="userEmail"
            class="mt-5"
            :label="$i18n.t('actions.enter_email')"
            v-on:keyup.enter="sendUserEmail"
            append-icon="mdi-keyboard-return"
          ></v-text-field> -->
        </div>
      </v-flex>
    </v-layout>
    <v-dialog width="600px" v-model="emailSentDialog" persistent>
      <v-card class="text-center pa-5">
        <p class="title font-weight-light">
          {{ $i18n.t("actions.sending_mail") }}
        </p>
        <v-progress-circular
          :size="50"
          color="#EF5350"
          indeterminate
        ></v-progress-circular>
      </v-card>
    </v-dialog>
    <v-dialog max-width="1000px" v-model="gdprDialog">
      <v-card
        class="mainContainerBackground pa-5"
        :class="$vuetify.breakpoint.smAndDown ? 'textSizeMobile' : 'textSize'"
      >
        <div class="pa-3">
          <p>{{ $i18n.t("gdpr_dialog.line_1") }}</p>
          <p>{{ $i18n.t("gdpr_dialog.line_2") }}</p>
          <p>{{ $i18n.t("gdpr_dialog.line_3") }}</p>
          <p>{{ $i18n.t("gdpr_dialog.line_4") }}</p>
          <p>{{ $i18n.t("gdpr_dialog.line_5") }}</p>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog width="600px" v-model="downloadPdfDialog" persistent>
      <v-card class="text-center pa-5">
        <p class="title font-weight-light">
          {{ $i18n.t("actions.downloading_pdf") }}
        </p>
        <v-progress-circular
          :size="50"
          color="#EF5350"
          indeterminate
        ></v-progress-circular>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    formResults: {
      type: Array,
    },
    formId: {
      type: Number,
    },
  },
  data() {
    return {
      resultCategories: [],
      emailSentDialog: false,
      emailToUserSent: false,
      gdprDialog: false,
      downloadPdfDialog: false,
      userEmail: "",
      userProfiles: [
        {
          text: this.$i18n.t("romantic_fraud.profile_1_result"),
        },
        {
          text: this.$i18n.t("romantic_fraud.profile_2_result"),
        },
        {
          text: this.$i18n.t("romantic_fraud.profile_3_result"),
        },
      ],
      userProfile: null,
    };
  },
  methods: {
    getResultCategories() {
      this.$axios
        .get("api/form_builder/result_category?form_id=" + this.formId)
        .then((response) => {
          this.resultCategories = response.data;
          console.log(response.data);
          this.setResultCategoriesScore();
        });
    },
    setResultCategoriesScore() {
      this.formResults.forEach((question) => {
        console.log(question);
        if (question.type == "q_multiple_choice") {
          let findResultCategory = this.resultCategories.find(
            (i) => i.id == question.result_category
          );
          let parsedOptions = JSON.parse(question.options);
          let allQuestionOptionValues = parsedOptions.map((i) =>
            Number(i.weight)
          );
          let highestQuestionScore = Math.max(...allQuestionOptionValues);
          if (findResultCategory && findResultCategory.total_score) {
            findResultCategory.total_score =
              findResultCategory.total_score + highestQuestionScore;
            findResultCategory.user_score =
              findResultCategory.user_score + question.answer;
          } else {
            findResultCategory.total_score = highestQuestionScore;
            findResultCategory.user_score = question.answer;
          }
        }
      });
      this.setResultProfile();
    },
    setResultProfile() {
      let calculatedScore =
        (this.resultCategories[0].user_score /
          this.resultCategories[0].total_score) *
        100;
      console.log(calculatedScore);
      if (calculatedScore > 70) {
        this.userProfile = this.userProfiles[0];
      } else if (calculatedScore < 40) {
        this.userProfile = this.userProfiles[2];
      } else {
        this.userProfile = this.userProfiles[1];
      }
    },
    // downloadPdf() {
    //   this.downloadPdfDialog = true;
    //   axios({
    //     url: `https://flightform.prios.no/result_pdf/${this.$i18n.locale}/${this.userProfile.content_to_use}.pdf`,
    //     method: "get",
    //     responseType: "blob",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Accept: "application/pdf",
    //     },
    //   })
    //     .then((getPdfData) => {
    //       this.downloadPdfDialog = false;
    //       const url = window.URL.createObjectURL(new Blob([getPdfData.data]));
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.setAttribute("download", this.userProfile.pdf_name);
    //       link.setAttribute("target", "_blank");
    //       link.click();
    //     })
    //     .catch((err) => {
    //       this.downloadPdfDialog = false;
    //       alert("Something went wrong");
    //     });
    // },
    // sendUserEmail() {
    //   if (!this.emailToUserSent) {
    //     if (
    //       this.userEmail.length != 0 &&
    //       this.userEmail.includes("@") &&
    //       this.userEmail.includes(".")
    //     ) {
    //       this.emailSentDialog = true;
    //       axios
    //         .get(
    //           `https://flightform.prios.no/email_html/${this.$i18n.locale}/${this.userProfile.content_to_use}.html`
    //         )
    //         .then(async (getHtmlContent) => {
    //           let getEmailText = await axios.get(
    //             `https://flightform.prios.no/text_templates/${this.$i18n.locale}/${this.userProfile.content_to_use}.txt`
    //           );
    //           let createObj = {
    //             mail_data: {
    //               to: this.userEmail,
    //               from: "Flight",
    //               subject: this.userProfile.email_subject,
    //               html: getHtmlContent.data,
    //               text: getEmailText.data,
    //             },
    //           };
    //           this.$axios.post("/api/email_service", createObj).then(() => {
    //             this.userEmail = "";
    //             setTimeout(() => {
    //               this.emailSentDialog = false;
    //               this.emailToUserSent = true;
    //             }, 1000);
    //           });
    //         })
    //         .catch((err) => {
    //           this.emailSentDialog = false;
    //           alert(this.$i18n.t("actions.email_error"));
    //         });
    //     }
    //   } else {
    //     alert(this.$i18n.t("actions.email_allready_recieved"));
    //   }
    // },
  },
  mounted() {
    this.getResultCategories();
  },
};
</script>
<style scoped>
.textSize {
  font-size: 20px;
}
.textSizeMobile {
  font-size: 14px;
}
.clickableLinkStyle {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
</style>
