<template>
  <div class="pa-5">
    <v-layout row wrap justify-center v-if="userProfile">
      <v-flex xs10>
        <div class="font-weight-light text-center pa-2">
          <div
            v-html="userProfile.text"
            :class="
              $vuetify.breakpoint.smAndDown ? 'textSizeMobile' : 'textSize'
            "
          ></div>
          <br />
          <div
            :class="
              $vuetify.breakpoint.smAndDown ? 'textSizeMobile' : 'textSize'
            "
          >
            <p>{{ $i18n.t("form_done_text") }}</p>
          </div>
          <div>
            <p class="clickableLinkStyle" @click="gdprDialog = true">
              {{ $i18n.t("actions.view_gdpr") }}
            </p>
            <p @click="downloadPdf" class="clickableLinkStyle">
              {{ $i18n.t("actions.download_pdf") }}
            </p>
          </div>
          <v-text-field
            v-model="userEmail"
            class="mt-5"
            :label="$i18n.t('actions.enter_email')"
            v-on:keyup.enter="sendUserEmail"
            append-icon="mdi-keyboard-return"
          ></v-text-field>
        </div>
      </v-flex>
    </v-layout>
    <v-dialog width="600px" v-model="emailSentDialog" persistent>
      <v-card class="text-center pa-5">
        <p class="title font-weight-light">
          {{ $i18n.t("actions.sending_mail") }}
        </p>
        <v-progress-circular
          :size="50"
          color="#EF5350"
          indeterminate
        ></v-progress-circular>
      </v-card>
    </v-dialog>
    <v-dialog max-width="1000px" v-model="gdprDialog">
      <v-card
        class="mainContainerBackground pa-5"
        :class="$vuetify.breakpoint.smAndDown ? 'textSizeMobile' : 'textSize'"
      >
        <div class="pa-3">
          <p>{{ $i18n.t("gdpr_dialog.line_1") }}</p>
          <p>{{ $i18n.t("gdpr_dialog.line_2") }}</p>
          <p>{{ $i18n.t("gdpr_dialog.line_3") }}</p>
          <p>{{ $i18n.t("gdpr_dialog.line_4") }}</p>
          <p>{{ $i18n.t("gdpr_dialog.line_5") }}</p>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog width="600px" v-model="downloadPdfDialog" persistent>
      <v-card class="text-center pa-5">
        <p class="title font-weight-light">
          {{ $i18n.t("actions.downloading_pdf") }}
        </p>
        <v-progress-circular
          :size="50"
          color="#EF5350"
          indeterminate
        ></v-progress-circular>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    formResults: {
      type: Array,
    },
    formId: {
      type: Number,
    },
  },
  data() {
    return {
      resultCategories: [],
      userProfile: null,
      emailSentDialog: false,
      emailToUserSent: false,
      gdprDialog: false,
      downloadPdfDialog: false,
      userProfiles: [
        {
          financial_id: 231,
          investment_id: 232,
          content_to_use: "confident-navigator",
          pdf_name: "#FLIGHT Profile 1 Confident Navigator 2023.pdf",
          text: this.$i18n.t("profile_1"),
          email_subject:
            "Congratulations on Assessing Your Financial Potential!",
        },
        {
          financial_id: 231,
          investment_id: 233,
          content_to_use: "knowledgeable-seeker",
          pdf_name: "#FLIGHT Profile 2 Knowledgeable Seeker 2023.pdf",
          text: this.$i18n.t("profile_2"),
          email_subject:
            "Celebrating Your Financial Awareness and Learning Potential!",
        },
        {
          financial_id: 234,
          investment_id: 232,
          content_to_use: "risk-adverse-explorer",
          pdf_name: "#FLIGHT Profile 3 Risk Adverse Explorer 2023.pdf",
          text: this.$i18n.t("profile_3"),
          email_subject:
            "Commending Your Financial Discipline and Saving Habits!",
        },
        {
          financial_id: 234,
          investment_id: 233,
          content_to_use: "novice-navigator",
          pdf_name: "#FLIGHT Profile 4 Novice Navigator 2023.pdf",
          text: this.$i18n.t("profile_4"),
          email_subject:
            "Embracing Your Journey as a Novice Navigator in Financial Growth!",
        },
      ],
      userEmail: "",
    };
  },
  methods: {
    getResultCategories() {
      this.$axios
        .get("api/form_builder/result_category?form_id=" + this.formId)
        .then((response) => {
          this.resultCategories = response.data;
          this.setResultCategoriesScore();
        });
    },
    setResultCategoriesScore() {
      this.formResults.forEach((question) => {
        if (question.type == "q_multiple_choice") {
          let findQuestionAnswer = JSON.parse(question.options).find(
            (i) => i.label == question.answer
          );
          let findResultCategory = this.resultCategories.find(
            (i) => i.id == findQuestionAnswer.result_category_id
          );
          if (findResultCategory && !findResultCategory.user_score) {
            findResultCategory.user_score = 1;
          } else if (findResultCategory && findResultCategory.user_score) {
            findResultCategory.user_score++;
          }
        }
      });
      this.setResultProfile();
    },
    setResultProfile() {
      let getFinancialCategory = this.resultCategories
        .filter((i) => i.id == 231 || i.id == 234)
        .reduce((max, category) =>
          max.user_score > category.user_score ? max : category
        );
      let getInvestmentCategory = this.resultCategories
        .filter((i) => i.id == 232 || i.id == 233)
        .reduce((max, category) =>
          max.user_score > category.user_score ? max : category
        );
      this.userProfile = this.userProfiles.find(
        (i) =>
          i.financial_id == getFinancialCategory.id &&
          i.investment_id == getInvestmentCategory.id
      );
    },
    downloadPdf() {
      this.downloadPdfDialog = true;
      axios({
        url: `https://flightform.prios.no/result_pdf/${this.$i18n.locale}/${this.userProfile.content_to_use}.pdf`,
        method: "get",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
        .then((getPdfData) => {
          this.downloadPdfDialog = false;
          const url = window.URL.createObjectURL(new Blob([getPdfData.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.userProfile.pdf_name);
          link.setAttribute("target", "_blank");
          link.click();
        })
        .catch((err) => {
          this.downloadPdfDialog = false;
          alert("Something went wrong");
        });
    },
    sendUserEmail() {
      if (!this.emailToUserSent) {
        if (
          this.userEmail.length != 0 &&
          this.userEmail.includes("@") &&
          this.userEmail.includes(".")
        ) {
          this.emailSentDialog = true;
          axios
            .get(
              `https://flightform.prios.no/email_html/${this.$i18n.locale}/${this.userProfile.content_to_use}.html`
            )
            .then(async (getHtmlContent) => {
              let getEmailText = await axios.get(
                `https://flightform.prios.no/text_templates/${this.$i18n.locale}/${this.userProfile.content_to_use}.txt`
              );
              let createObj = {
                mail_data: {
                  to: this.userEmail,
                  from: "Flight",
                  subject: this.userProfile.email_subject,
                  html: getHtmlContent.data,
                  text: getEmailText.data,
                },
              };
              this.$axios.post("/api/email_service", createObj).then(() => {
                this.userEmail = "";
                setTimeout(() => {
                  this.emailSentDialog = false;
                  this.emailToUserSent = true;
                }, 1000);
              });
            })
            .catch((err) => {
              this.emailSentDialog = false;
              alert(this.$i18n.t("actions.email_error"));
            });
        }
      } else {
        alert(this.$i18n.t("actions.email_allready_recieved"));
      }
    },
  },
  mounted() {
    this.getResultCategories();
  },
};
</script>
<style scoped>
.textSize {
  font-size: 20px;
}
.textSizeMobile {
  font-size: 14px;
}
.clickableLinkStyle {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
</style>
